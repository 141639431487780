<template>
  <div class="BasketThankYou ui container" v-if="status">
    <Info v-if="status === 'OK'" type="positive" @close="status = null">
      Dziękujemy za płatność. Wkrótce na email otrzymasz potwierdzenie.
    </Info>
    <Info v-if="status === 'FAIL'" type="negative" @close="status = null">
      Przepraszamy, wystąpił błąd podczas płatności.
    </Info>
  </div>
</template>

<script>
import Info from '@/common/components/Info.vue';

export default {
  name: 'BasketThankYou',
  components: {
    Info
  },
  data() {
    return {
      status: null,
    };
  },
  created() {
    const { status } = this.$route.query;

    if (status === 'OK' || status === 'FAIL') {
      this.status = status;

      this.$router.replace(window.location.pathname);
    }
  }
};
</script>

<style lang="scss">
 .BasketThankYou {
   margin: 40px 0;
 }
</style>
