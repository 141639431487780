<template>
  <div class="ui modal basket-modal">
    <i class="close icon"></i>
    <div class="header">
      Rezerwacja lekcji
    </div>
    <div class="content">
      <h2 class="ui header">
        {{printDay(currentAddToBasketItem.timestamp)}} <br />
        godz. {{printHour(currentAddToBasketItem.timestamp)}}
      </h2>
      <h4 class="ui header">Dostępne opcje:</h4>
      <div class="ui card">
        <div class="content ui form">
          <div class="field" v-for="(price, index) in currentAddToBasketItem.prices" :key="price.length">
            <div class="ui radio checkbox" @click="onPriceChange(index)">
              <input type="radio" name="classLength" v-model="priceIndex" :value="index" />
              <label><b>{{price.name}}</b>, {{price.displayLength}} minut, {{price.price}} zł</label>
            </div>
          </div>

        </div>
      </div>

    </div>
    <div class="actions">
      <div class="ui large primary button" @click="addToBasket">Dodaj do koszyka</div>
      <div class="ui large green button" @click="addToBasketAndProceed">Kup teraz</div>
    </div>
  </div>
</template>

<script>
import calendarHelpers from '@/common/mixins/calendarHelpers';
import { pluck } from 'underscore';

export default {
  name: 'BasketAddItemModal',
  mixins: [calendarHelpers],
  props: {
    close: {
      type: Function
    }
  },
  data() {
    return {
      priceIndex: null,
    };
  },
  mounted() {
    const self = this;

    if (this.currentAddToBasketItem && this.currentAddToBasketItem.prices.length > 0) {
      this.priceIndex = this.$store.state.basket.lastSelectedPriceIndex !== null
        ? this.$store.state.basket.lastSelectedPriceIndex : (this.currentAddToBasketItem.prices.length - 1);
    }

    if (this.currentAddToBasketItem) {
      $(this.$el).modal({
        onHidden() {
          self.$emit('close-modal');
        }
      }).modal('show');
    }
  },
  unmounted() {
    this.$store.commit('setCurrentAddToBasketItem', null);
  },
  methods: {
    addToBasket() {
      const price = this.currentAddToBasketItem.prices[this.priceIndex];

      const basketItem = {
        timestamp: this.currentAddToBasketItem.timestamp,
        length: price.length,
        displayLength: price.displayLength,
        price: price
      };

      this.$store.commit('addItem', basketItem);
      $(this.$el).modal('hide');
    },
    addToBasketAndProceed() {
      this.addToBasket();
      this.$store.commit('setBasketCheckoutModalState', true);
    },
    onPriceChange(index) {
      this.priceIndex = index;
      this.$store.commit('SET_LAST_SELECTED_PRICE_INDEX', index);
    },
  },
  computed: {
    currentAddToBasketItem() {
      return this.$store.state.basket.currentAddToBasketItem || {};
    }
  }
};
</script>

<style lang="scss">
.basket-modal {
  .ui.checkbox {
    cursor: pointer;

    label {
      cursor: pointer;
    }
  }

  .ui.content {
    text-align: left;
  }

  .ui.card {
    margin: 0 auto;
  }

  .field {
    line-height: 2em;

    label {
      font-size: 1.2em;
    }
  }
}
</style>
