<template>
  <div class="my-class-card ui raised card single-class" :class="{'--past': isPast, '--active': isActive, '--future': isFuture}">
    <div class="content">
      <div class="header">{{getDate(myClass.start)}} <br /> {{getHour(myClass.start)}}</div>
      <div class="meta">
        <span class="category">{{getDisplayLength(myClass.length)}} minut</span>
      </div>
      <div class="description">
        <p v-if="isPaid">
          opłacona
        </p>
        <p v-else-if="isPaymentPending">
          oczekuje na płatność
        </p>
        <p v-else>
          nieopłacona
        </p>
      </div>
    </div>
    <div v-if="isFuture && isPaid" class="extra content">
      <div class="ui animated tiny button" :class="{'disabled': !isChangeDateAvailable}" @click="openChangeClassDate(myClass)">
        <div class="visible content">Zmień date</div>
        <div class="hidden content">
          <i class="right arrow icon"></i>
        </div>
      </div>
    </div>
    <div v-if="!isPaid && !isPaymentPending && myClass.priceListId" class="extra content">
      <div class="ui animated tiny button" :class="{ positive: isInBasket, blue: !isInBasket}" @click="addToShoppingBag(myClass)">
        <div v-if="!isInBasket" class="visible content ">Dodaj do koszyka</div>
        <div v-else class="visible content">W koszyku</div>
        <div class="hidden content">
          <i class="right shopping cart icon"></i>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import basketHelpers from '@/common/mixins/basketHelpers';
import priceHelpers from '@/common/mixins/priceHelpers';

export default {
  name: 'MyClassCard',
  mixins: [
    basketHelpers,
    priceHelpers,
  ],
  props: {
    myClass: {
      type: Object,
      default: () => {},
    },
    type: String,
    openChangeClassModal: Function,
  },
  computed: {
    isActive() {
      return this.type === 'active';
    },
    isFuture() {
      return this.type === 'future';
    },
    isPast() {
      return this.type === 'past';
    },
    isChangeDateAvailable() {
      return this.myClass.start - (24 * 60 * 60 * 1000) > this.moment().valueOf();
    },
    isPaid() {
      return this.myClass.isPaid === true;
    },
    isPaymentPending() {
      return !this.myClass.isPaid && this.myClass.transactionId;
    },
    isInBasket() {
      return !!this.basketItems[this.myClass.start];
    },
  },
  methods: {
    getDate(timestamp) {
      return this.moment(timestamp).format('DD.MM.YYYY, dddd');
    },
    getHour(timestamp) {
      return this.moment(timestamp).format('kk:mm');
    },
    openChangeClassDate(singleClass) {
      this.$store.commit('SET_CLASS_TO_BE_CHANGED', singleClass);
      this.openChangeClassModal();
    },
    addToShoppingBag(singleClass) {
      const alreadyExists = this.$store.state.basket.items.find((i) => i.timestamp === singleClass.start);
      const priceList = this.$store.state.tutorPage.studentPriceLists.find((p) => p.id === singleClass.priceListId);

      if (alreadyExists) {
        this.$store.dispatch('addNotification', { type: 'failure', msg: 'Lekcja została już dodana do koszyka' });
      } else if (priceList) {
        const basketItem = {
          timestamp: singleClass.start,
          length: singleClass.length,
          displayLength: singleClass.length,
          price: priceList
        };

        this.$store.commit('addItem', basketItem);
        this.$store.dispatch('addNotification', { type: 'success', msg: 'Lekcja dodana do koszyka.' });
      }
    },
  },
};
</script>

<style lang="scss">
.my-class-card.ui.card.single-class {
  margin: 1em 20px;
  width: auto;
  min-width: 250px;

  &.--past {
    background-color: #e8e8e8;
  }
}
</style>
