import { createRouter, createWebHistory } from 'vue-router';
import TutorPage from './views/TutorPage.vue';

const router = createRouter({
  mode: 'history',
  history: createWebHistory(process.env.BASE_URL),
  routes: [
    {
      path: '/:name',
      component: TutorPage,
      meta: {
        pageType: 'homepage',
      },
    },
    {
      path: '/:name/draft',
      component: TutorPage,
      meta: {
        pageType: 'homepage',
        isDraft: true
      },
    },
    {
      path: '/:name/calendar',
      component: TutorPage,
      meta: {
        pageType: 'calendar',
      },
    },
    {
      path: '/:name/register',
      component: TutorPage,
      meta: {
        pageType: 'register',
      },
    },
    {
      path: '/about',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      // component: () => import(/* webpackChunkName: "about" */ './views/About.vue'),
    },
  ],
});

export default router;
