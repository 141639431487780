/* eslint-disable max-len */

<template>
  <div class="tutor-header" :class="{isStudentAuthenticated: 'logged-in'}">
    <nav class="ui container">
      <div>
        <div class="logo-name ui medium header"><a href="/" class="tutor-link">Tutorro.pl</a><span>/</span><a :href="'/' + route" class="route">{{route}}</a></div>
        <div v-if="isStudentAuthenticated" class="tutor-header__name">Student: {{studentName}}</div>
      </div>

      <ul v-if="isStudentAuthenticated" class="top-navigation">
        <li><a href="#" @click.prevent="$emit('sign-out')" class="sign-out"><i class="sign out alternate icon"></i></a></li>
      </ul>
      <ul v-else class="top-navigation">
        <!--<li><a :href="'/' + route + '/calendar'">Kalendarz</a></li>-->
        <li><a href="#" @click.prevent="$emit('open-login')" class="login">Logowanie dla ucznia</a></li>
      </ul>
      <BasketIcon />
    </nav>
  </div>
</template>

<script>
import BasketIcon from './Basket/BasketIcon.vue';

export default {
  name: 'TutorHeader',
  components: {
    BasketIcon,
  },
  props: {
    route: '',
    isHomepage: true,
    isStudentAuthenticated: false,
    onSignOut: () => {}
  },
  data() {
    return {
      bookingTimestamp: 0,
      alternativeView: false
    };
  },
  computed: {
    studentName() {
      return `${this.$store.state.tutorPage.studentData.firstName || ''} ${this.$store.state.tutorPage.studentData.lastName || ''}`;
    },
  },
};
</script>

<style lang="scss">
  .tutor-header {
    padding: 10px 0;
    background-color: white;
    box-shadow: 0 2px 7px -3px rgba(0, 0, 0, 0.2);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 999;

    nav {
      padding: 7px 0;
    }

    &__name {
      margin-right: 30px;
    }

    .container {
      position: relative;
      display: flex;
      justify-content: space-between;

      .logo-name {
        cursor: pointer;
        font-size: 24px;
        margin-top: 0;
        margin-bottom: 0;

        .tutor-link {
          color: #252525;
        }

        .route {
          color: #ff5252;
          font-size: 1.1em;
        }
      }

      .top-navigation {
        display: flex;
        margin: 0 50px 0 0;

        li {
          list-style: none;
          line-height: 31px;

          a {
            color: #4183C4;
            font-size: 14px;
            line-height: 30px;
            font-weight: 700;
            text-transform: uppercase;
            padding: 10px 15px;

            &:hover {
              color: #34ace0;
            }

            &.login {
              color: #fff;
              background: #1089d4;
              border: 1px solid #1089d4;
              border-radius: 11px 11px;
              padding: 12px 18px;
              margin-left: 10px;
              margin-right: 15px;
            }
          }
        }

        .sign-out {
          color: #000;
        }

        .icon.sign.out {
          font-size: 2.5em;
          position: relative;
          top: 4px;
        }
      }
    }
  }

  @media only screen and (max-width: 900px) {
    .tutor-header {
      padding: 5px 0;

      &__name {
        margin-right: 0;
      }
    }
  }

  @media only screen and (max-width: 400px) {
    .tutor-header {
      .logo-name  {
        .tutor-link {
          & + span {
            display: none;
          }

          display: none;
        }
      }
    }
  }
</style>
