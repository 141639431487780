<template>
  <div class="public-calendar">
    <template v-if="showHeader">
      <div class="public-calendar__top">
        <h1>Dostępne terminy</h1>
      </div>
      <div class="ui divider"></div>
    </template>
    <div class="Calendar">
      <CalendarHeader
        :title="calendarDate"
        :go-today="setCurrentWeekAsToday"
        :go-later="setNextWeek"
        :go-earlier="setPreviousWeek"
        :show-today-button="showTodayButton"
        :show-go-earlier-button="showGoEarlierButton"
        :show-go-later-button="showGoLaterButton"
      ></CalendarHeader>
      <div class="CalendarTimezone">Czas wg Twojej strefy czasowej {{timezoneText}}</div>
      <CalendarMatrix
        :current-week="currentWeek"
        :available-slots="availableSlots"
        :hours-range="hoursRange"
        :public-mode="true"
        :booking-enabled="bookingEnabled"
        :booking-label="bookingLabel"
        :is-not-available="isNotAvailable && !isLoadingAvailability"
      >
        <div class="ui inverted dimmer" v-bind:class="{ 'active': isLoading }">
          <div class="ui text loader">Ładowanie...</div>
        </div>
      </CalendarMatrix>
    </div>
  </div>
</template>

<script>
import Api from '@/api/api';
import calendarHelpers from '../../common/mixins/calendarHelpers';
import CalendarHeader from '../../common/components/Calendar/CalendarHeader.vue';
import CalendarMatrix from '../../common/components/Calendar/CalendarMatrix.vue';

export default {
  name: 'public-calendar',
  components: {
    CalendarHeader,
    CalendarMatrix,
  },
  props: {
    bookingEnabled: {
      type: Boolean,
      default: false,
    },
    bookingLabel: {
      type: String,
      default: 'Kliknij, aby zarezerwowac',
    },
    showHeader: {
      type: Boolean,
      default: false,
    },
    mode: {
      type: String,
      default: 'booking'
    },
  },
  mixins: [calendarHelpers],
  data() {
    return {
      isLoadingAvailability: false,
      isNotAvailable: false,
      currentWeek: {
        start: null,
        end: null
      },
      hoursRange: {
        start: 7,
        end: 19,
      },
      generalAvailability: {},
      teacherTimezone: '',
      availableSlots: {},
    };
  },
  created() {
    this.$store.commit('SET_MODE', this.mode);

    this.moment.locale('pl');
    this.setCurrentWeek();
    this.loadAvailability();
  },
  computed: {
    isLoading() {
      return this.$store.state.calendar.isLoadingBookedSlots || this.isLoadingAvailability;
    },
    teacherId() {
      return this.$store.state.tutorPage.teacherId;
    },
    calendarDate() {
      return `${this.moment(this.currentWeek.start).format('Do MMM YYYY')} - ${
        this.moment(this.currentWeek.end - 1).format('Do MMM YYYY')}`;
    },
    showTodayButton() {
      return this.currentWeek.start !== this.moment().startOf('isoWeek').valueOf();
    },
    showGoEarlierButton() {
      return this.getCalendarWeekDifferenceToCurrentWeek() > 0;
    },
    showGoLaterButton() {
      return this.getCalendarWeekDifferenceToCurrentWeek() >= 0 && this.getCalendarWeekDifferenceToCurrentWeek() < 12;
    },
  },
  methods: {
    loadAvailability() {
      const self = this;
      const reqData = {
        teacherId: this.teacherId,
        timestampFrom: this.currentWeek.start,
        timestampTo: this.currentWeek.end
      };

      this.isLoadingAvailability = true;

      Api.getAvailability(reqData)
        .then((response) => {
          self.teacherTimezone = response.data.timezone;
          self.generalAvailability = self.convertGeneralAvailabilityToLocalTimezone(response.data.times, self.currentWeek.start, self.teacherTimezone);
          self.availableSlots = self.convertGeneralAvailabilityToTimestamps(self.generalAvailability, self.currentWeek.start);
          self.hoursRange = self.getHoursRange(self.generalAvailability);
          self.checkAvailability();
        })
        .catch((error) => {
          console.log(error);
          setTimeout(window.location.reload, 5000);
        })
        .then(() => self.isLoadingAvailability = false);
    },

    getBookedSlots() {
      const reqData = {
        teacherId: this.teacherId,
        timestampFrom: this.currentWeek.start,
        timestampTo: this.currentWeek.end
      };

      this.$store.dispatch('loadBookedSlots', reqData);
    },

    checkAvailability() {
      const from = this.moment().add(1, 'day').startOf('day');
      this.isNotAvailable = !this.isTimestampBetween(from.valueOf(), this.currentWeek.end, this.availableSlots);
    },

    getCalendarWeekDifferenceToCurrentWeek() {
      return this.moment(this.currentWeek.start).diff(this.moment().startOf('isoWeek'), 'weeks');
    },

    setCurrentWeek(start) {
      this.currentWeek.start = this.moment(start).startOf('isoWeek').valueOf();
      this.currentWeek.end = this.moment(start).startOf('isoWeek').add(1, 'w').valueOf();
      this.availableSlots = this.convertGeneralAvailabilityToTimestamps(this.generalAvailability, this.currentWeek.start);
      this.getBookedSlots();
      this.checkAvailability();
    },
    setPreviousWeek() {
      this.setCurrentWeek(this.moment(this.currentWeek.start).subtract(1, 'w').valueOf());
    },
    setNextWeek() {
      this.setCurrentWeek(this.moment(this.currentWeek.start).add(1, 'w').valueOf());
    },
    setCurrentWeekAsToday() {
      this.setCurrentWeek(this.moment().startOf('isoWeek').valueOf());
    },
  },
};
</script>

<style lang="scss">
.public-calendar {
  &__top {
    position: relative;
  }

  .Calendar {
    position: relative;
    text-align: center;

    * {
      box-sizing: border-box;
    }
  }

  .public-calendar__timezone {
    float: right;
    font-size: .5em;
  }

  .CalendarTimezone {
    padding: 0 0 10px;
    font-size: .8em;
  }
}

@media only screen and (max-width: 850px) {
  html {
    font-size: 11px;
  }

  .public-calendar {
    margin: 5px;

    .CalendarHeader {
      width: 100%;
    }

    .CalendarHeader .CalendarHeader__date {
      font-size: 1.4em;
    }

    .CalendarHeader__button--today {
      display: none;
    }

    .CalendarMatrix__column-legend {
      max-width: 45px;
      font-size: .8em;
      align-items: center;
      padding: 0;
    }
  }
}
</style>
