import Firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';
import 'firebase/compat/functions';

import StudentsApi from './students';
import PriceListsApi from "@/api/priceLists";
import EventsApi from './events';
import BasketApi from './basket';
import TutorsApi from './tutors';
import TransactionsApi from './transactions';
import CalendarApi from './calendar';
import FileUploadApi from './fileUpload';
import store from '../store';

// Initialize Firebase
const config = {
  apiKey: process.env.VUE_APP_FB_API_KEY,
  authDomain: process.env.VUE_APP_FB_AUTH_DOMAIN,
  databaseURL: process.env.VUE_APP_FB_DATABASE_URL,
  projectId: process.env.VUE_APP_FB_PROJECT_ID,
  storageBucket: process.env.VUE_APP_FB_STORAGE_BUCKET,
  messagingSenderId: process.env.VUE_APP_FB_MESSAGING_SENDER_ID,
};
const firebaseApp = Firebase.initializeApp(config);
const functions = firebaseApp.functions();
const firestore = firebaseApp.firestore();

window.firebase = firebaseApp;

if (process.env.VUE_APP_USE_LOCAL_FUNCTIONS === 'true') {
  functions.useFunctionsEmulator('http://127.0.0.1:5010');
}

export default {
  students: new StudentsApi(firestore, store.state),
  priceLists:  new PriceListsApi(firestore, store.state),
  events: new EventsApi(firestore, store.state),
  transactions: new TransactionsApi(firestore, store.state),
  basket: new BasketApi(firestore, store.state),
  tutors: new TutorsApi(firestore, store.state),
  calendar: new CalendarApi(firestore, store.state),
  fileUpload: new FileUploadApi(store.state),

  makeClassRequest(details) {
    return new Promise((resolve) => {
      // details
      const bookLesson = firebase.functions().httpsCallable('bookLesson');
      bookLesson(details).then(() => {
        resolve();
      });
    });
  },
  getBookedSlots(data) {
    const bookedSlotes = firebase.functions().httpsCallable('getBookedSlots');
    return bookedSlotes(data);
  },
  getAvailability(data) {
    const availability = firebase.functions().httpsCallable('getAvailability');
    return availability(data);
  },
  saveEmailSubscription(email) {
    const availability = firebase.functions().httpsCallable('addEmail');
    return availability({ email });
  },
  getVideoAccessToken(userIdentity, roomName) {
    const videoAccessToken = firebase.functions().httpsCallable('getVideoAccessToken');
    const options = {
      userIdentity,
      roomName,
    };

    return new Promise((resolve) => {
      videoAccessToken(options).then((res) => {
        resolve(res.data);
      });
    });
  },
  createUserWithEmailAndPassword(email, password, additionalInfo) {
    return new Promise((resolve, reject) => {
      firebaseApp.auth().createUserWithEmailAndPassword(email, password)
        .then((userCredential) => {
          const { uid } = userCredential.user;
          const newUserRef = firestore.collection('teachers').doc(uid);
          newUserRef.set({
            firstName: additionalInfo.firstName,
            lastName: additionalInfo.lastName,
          })
            .then(() => {
              resolve();
            })
            .catch((error) => {
              reject(error);
            });
        })
        .catch((error) => {
          reject(error.message);
        });
    });
  },
  onAuthStateChanged(cb) {
    firebaseApp.auth().onAuthStateChanged(cb);
  },
  signOut() {
    return firebaseApp.auth().signOut();
  },
  signInWithEmailAndPassword(email, password) {
    return firebaseApp.auth().signInWithEmailAndPassword(email, password);
  },
  changePassword(password, newPassword) {
    const user = firebaseApp.auth().currentUser;

    const credentials = Firebase.auth.EmailAuthProvider.credential(
      user.email,
      password
    );

    return user.reauthenticateWithCredential(credentials)
      .then(() => user.updatePassword(newPassword));
  },
  sendPasswordResetEmail(email) {
    return firebaseApp.auth().sendPasswordResetEmail(email);
  },
  getUserSettings(uid) {
    return new Promise((resolve, reject) => {
      const userRef = firestore.collection('teachers').doc(uid);

      userRef.get()
        .then((doc) => {
          resolve(doc);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  generateDotPayCheckSum(params) {
    return firebase.functions().httpsCallable('getDotPayCheckSum')(params);
  },
  getRandomDocId() {
    return firestore.collection('random').doc().id;
  },
  stripeGenerateCheckoutLink(data) {
    return firebase.functions().httpsCallable('stripeGenerateCheckoutLink')(data);
  },
  confirmBankTransferBooking(data) {
    return firebase.functions().httpsCallable('confirmBankTransferBooking')(data);
  }
};
