<template>
  <div class="ui modal BasketCheckoutModal">
    <i class="close icon"></i>
    <div class="header">
      Podsumowanie
    </div>
    <div class="content">
      <div v-if="isPaymentRedirectionPending">
        <Loader text="Trwa przekierowanie do systemu płatności..." :is-inverted="true" />
      </div>
      <div v-else-if="isBankTransferConfirmed">
        <div class="ui icon success message">
          <i class="thumbs up icon"></i>
          <div class="content">
            <div class="header">
              Lekcje zostały zarezerwowane
            </div>
            <p>Przejdź teraz na swoje konto i dokonaj przelewu.</p>
          </div>
        </div>

        <h3 class="ui header">Dane do przelewu:</h3>

        <form class="ui form">
          <div class="field">
            <label>Nazwa odbiorcy</label>
            <div class="ui action input">
              <input type="text" v-model="transferBankAccountName" readonly>
              <button type="button" name="copyToken" value="copy" class="ui right icon button" @click="copyToClipboard">
                <i class="clipboard icon"></i>
              </button>
            </div>
          </div>
          <div class="field">
            <label>Numer konta / BLIK telefon</label>
            <div class="ui action input">
              <input type="text" v-model="transferBankAccountNumber" readonly>
              <button type="button" name="copyToken" value="copy" class="ui right icon button" @click="copyToClipboard">
                <i class="clipboard icon"></i>
              </button>
            </div>
          </div>
          <div class="field">
            <label>Kwota PLN</label>
            <div class="ui action input">
              <input type="text" v-model="bankTransferPrice" readonly>
              <button type="button" name="copyToken" value="copy" class="ui right icon button" @click="copyToClipboard">
                <i class="clipboard icon"></i>
              </button>
            </div>
          </div>
        </form>

      </div>
      <div v-else-if="items.length < 1">
        <div class="ui large header emptyBasket">
          <i class="ui icon shopping cart"></i> Twój koszyk jest pusty
        </div>
      </div>
      <div v-else>
        <table class="ui celled table productList">
          <thead>
            <tr>
              <th>Opis</th>
              <th class="priceHeader">Cena</th>
            </tr>
          </thead>
          <tbody>
          <tr v-for="(item, index) in items">
            <td data-label="Opis" class="productDescription">
              <b>{{item.price.name}}</b>, {{item.displayLength}} minut <br />
              {{printHour(item.timestamp)}}, {{printDay(item.timestamp)}}

              <button class="ui icon button removeButton" @click="removeItem(index)"><i class="close icon"></i></button>
            </td>
            <td data-label="Cena" class="productPrice">
              <span v-if="Number(getPrice(item)) < Number(item.price.price)">
                <s>{{item.price.price}}</s>
                <span class="promoPrice">&nbsp;{{getPrice(item)}} PLN</span>
              </span>
              <span v-else>{{getPrice(item)}} PLN</span>
            </td>
          </tr>
          </tbody>
        </table>

        <div v-if="!isStudentVerified" class="ui form checkoutForm">
          <div class="two fields">
            <div class="field">
              <label>Adres e-mail</label>
              <input placeholder="e-mail" type="text" v-model="email">
            </div>
<!--            <div class="field hidden">-->
<!--              <label>Kod promocyjny (jeśli posiadasz)</label>-->
<!--              <div class="ui action input">-->
<!--                <input placeholder="kod" type="text" v-model="coupon">-->
<!--                <button class="ui button" @click="verifyCoupon()" :class="{loading: isLoadingCoupon}">Zastosuj</button>-->
<!--              </div>-->
<!--            </div>-->
          </div>

          <div class="field hidden">
            <label>Uwagi, dodatkowe informacje</label>
            <textarea v-model="info" rows="2"></textarea>
          </div>
        </div>

        <Info v-if="success" type="success" v-on:close="success = ''">
          {{success}}
        </Info>
        <Info v-if="error" type="error" v-on:close="error =''">
          {{error}}
        </Info>

        <div class="ui header large">
          Razem: {{total}} PLN
        </div>

        <h3 class="ui header">
          <i class="money check icon"></i>
          <div class="content">
            Wybierz sposób płatności
          </div>
        </h3>

        <div v-if="showBankTransferPaymentOption">
          <h3 class="ui dividing header">
            Przelew bankowy / BLIK telefon
          </h3>
          <div class="ui primary large button" @click="payBankTransfer()" :class="{'loading': isBankTransferConfirmationPending}">Płacę przelewem bankowym</div>
        </div>

        <div v-if="showStripePaymentOption">
          <h3 class="ui dividing header">
            Płatności online - stripe.com
          </h3>
          <div class="ui primary large button" @click="payStripe()"
               :class="{'disabled': isBankTransferConfirmationPending}">
            Przejdź do płatności
          </div>
        </div>

        <div v-if="showPrzelewy24PaymentOption">
          <h3 class="ui dividing header">
            Płatności online - przelewy24.pl
          </h3>
          <div class="ui primary large button" @click="payPrzelewy()"
               :class="{'disabled': isBankTransferConfirmationPending}">
            Przejdź do płatności
          </div>
        </div>
      </div>
    </div>
    <div class="actions" v-if="isBankTransferConfirmed">
      <button class="ui positive right labeled icon button">
        Potwierdzam wykonanie przelewu
        <i class="checkmark icon"></i>
      </button>
    </div>
  </div>
</template>

<script>
import { groupBy } from 'underscore';
import tutorApi from '@/api/api';
import calendarHelpers from '@/common/mixins/calendarHelpers';
import priceHelpers from '@/common/mixins/priceHelpers';
import Info from '@/common/components/Info.vue';
import Loader from '@/common/components/Loader.vue';

export default {
  name: 'BasketCheckoutModal',
  components: {
    Info,
    Loader,
  },
  mixins: [
    calendarHelpers,
    priceHelpers,
  ],
  data() {
    return {
      email: '',
      coupon: '',
      info: '',
      error: '',
      success: '',
      isLoadingCoupon: false,
      isPaymentRedirectionPending: false,
      isBankTransferConfirmationPending: false,
      isBankTransferConfirmed: false,
      bankTransferPrice: null,
      couponPrice: null,
    };
  },
  created() {
    this.email = this.$store.state.tutorPage.studentData && this.$store.state.tutorPage.studentData.email || '';
  },
  mounted() {
    const self = this;

    $(this.$el).modal({
      onHidden() {
        self.$emit('close-modal');

        if (self.isBankTransferConfirmed) {
          self.$root.$emit('modal-checkout-transfer-confirmed-closed');
        }
      }
    }).modal('show');
  },
  computed: {
    items() {
      return this.$store.state.basket.items;
    },
    total() {
      let total = 0;

      this.items.map((item) => {
        total += Number(this.getPrice(item));
      });

      return total;
    },
    teacherId() {
      return this.$store.state.tutorPage.teacherId;
    },
    studentId() {
      return this.$store.state.authentication.authenticatedStudentId;
    },
    isStudentVerified() {
      return this.$store.state.tutorPage.isStudentVerified;
    },
    dotPayShopId() {
      return this.$store.state.tutorPage.publicSettings.dotPayShopId;
    },
    stripeChargesEnabled() {
      return this.$store.state.tutorPage.publicSettings.stripeChargesEnabled;
    },
    paymentBankTransferEnabled() {
      return this.$store.state.tutorPage.publicSettings.paymentBankTransferEnabled;
    },
    paymentStripeEnabled() {
      return this.$store.state.tutorPage.publicSettings.paymentStripeEnabled;
    },
    paymentPrzelewy24Enabled() {
      return this.$store.state.tutorPage.publicSettings.paymentPrzelewy24Enabled;
    },
    transferBankAccountName() {
      return this.$store.state.tutorPage.publicSettings.transferBankAccountName;
    },
    transferBankAccountNumber() {
      return this.$store.state.tutorPage.publicSettings.transferBankAccountNumber;
    },
    showBankTransferPaymentOption() {
      return this.paymentBankTransferEnabled && this.transferBankAccountName && this.transferBankAccountNumber;
    },
    showStripePaymentOption() {
      return this.paymentStripeEnabled && this.stripeChargesEnabled;
    },
    showPrzelewy24PaymentOption() {
      return this.paymentPrzelewy24Enabled && this.dotPayShopId;
    }
  },
  methods: {
    getPrice(item) {
      let finalPrice = item.price.price;
      const priceId = item.price.id;
      let sameItemsCount = 0;
      this.items.map((item) => {
        if (item.price.id === priceId) {
          sameItemsCount += 1;
        }
      });

      if (sameItemsCount > 1 && item.price.packages.length > 0) {
        item.price.packages.map((p) => {
          if (sameItemsCount >= p.count) {
            finalPrice = p.price;
          }
        });
      }

      return finalPrice;
    },
    groupByLength() {
      return groupBy(this.$store.state.basket.items, 'length');
    },
    getItemPackagePrice(classLength, count) {
      if (this.couponPrice) return this.couponPrice;

      const priceObj = this.getPriceSettings().find((el) => el.length === classLength);

      if (!priceObj) {
        return 'brak ceny';
      }

      const { prices } = priceObj;
      const packages = Object.keys(prices);

      let appliedPackage = 1;
      const len = packages.length;
      for (let i = 0; i < len; i++) {
        if (count >= packages[i]) {
          appliedPackage = packages[i];
        }
      }

      return prices[appliedPackage];
    },
    validatePayment() {
      let isValid = true;

      this.clearMessages();
      if (!this.email) {
        this.error = 'Proszę podać adres email.';
        isValid = false;
      }

      if (this.items.length === 0) {
        this.error = 'Brak lekcji w koszyku.';
        isValid = false;
      }

      if (isNaN(this.total)) {
        this.error = 'Brak zdefiniowanego/aktywnego cennika dla wszystkich lekcji. Skontaktuj się proszę z nauczycielem.';
        isValid = false;
      }

      return isValid;
    },
    payBankTransfer() {
      const self = this;

      if (!this.validatePayment()) {
        return;
      }

      const transactionId = Math.random().toString(36).substr(2, 10);

      const data = {
        teacherId: this.teacherId,
        studentId: this.studentId,
        description: `${this.items.length} x Lekcja online`,
        amount: `${this.total}.00`,
        currency: 'pln',
        transactionId,
        items: this.items,
        info: this.info,
        studentTimezone: this.timezone,
        email: this.email,
      };

      this.isBankTransferConfirmationPending = true;
      this.bankTransferPrice = this.total;

      tutorApi.confirmBankTransferBooking(data)
        .then((response) => {
          self.$store.commit('clearBasket');
          self.isBankTransferConfirmed = true;
        })
        .catch(() => {
          self.error = 'Wystąpił błąd podczas rezerwacji lekcji. Prosimy spróbować ponowanie lub skotantować się z nauczycielem.';
          self.isBankTransferConfirmationPending = false;
        });
    },
    payStripe() {
      const self = this;

      if (!this.validatePayment()) {
        return;
      }

      const transactionId = Math.random().toString(36).substr(2, 10);
      const paymentExpirationTimestamp = this.moment().add(24, 'h').valueOf();

      const data = {
        teacherId: this.teacherId,
        studentId: this.studentId,
        returnUrl: `${location.protocol}//${window.location.host}${window.location.pathname}`,
        description: `${this.items.length} x Lekcja online`,
        amount: `${this.total}.00`,
        currency: 'pln',
        transactionId,
        paymentExpirationTimestamp,
        items: this.items,
        info: this.info,
        studentTimezone: this.timezone,
        email: this.email,
      };

      self.isPaymentRedirectionPending = true;

      tutorApi.stripeGenerateCheckoutLink(data)
        .then((response) => {
          self.$store.commit('clearBasket');
          window.location.href = response.data.url;
        })
        .catch(() => {
          self.error = 'Wystąpił błąd podczas generowania linku płatności. Proszę spróbować ponowanie lub zgłosić problem.';
          self.isPaymentRedirectionPending = false;
        });
    },
    payPrzelewy() {
      const self = this;

      if (!this.validatePayment()) {
        return;
      }

      const transactionId = Math.random().toString(36).substr(2, 10);
      const paymentExpirationTimestamp = this.moment().add(10, 'm').valueOf();

      const params = {
        api_version: 'dev',
        lang: 'pl',
        id: this.dotPayShopId,
        amount: `${this.total}.00`,
        currency: 'PLN',
        description: `${this.items.length} x Lekcja online`,
        control: `${transactionId}|${this.teacherId}`,
        url: window.location.host + window.location.pathname,
        type: 0,
        urlc: `https://${process.env.VUE_APP_REGION}-${process.env.VUE_APP_FB_PROJECT_ID}.cloudfunctions.net/updateTransaction`,
        email: this.email,
        expiration_date: this.moment(paymentExpirationTimestamp).tz('Europe/Warsaw').format('YYYY-MM-DD HH:mm:ss')
      };

      const data = {
        teacherId: this.teacherId,
        studentId: this.studentId,
        transactionId,
        params,
        items: this.items,
        info: this.info,
        paymentExpirationTimestamp,
        studentTimezone: this.timezone,
      };

      self.isPaymentRedirectionPending = true;

      tutorApi.generateDotPayCheckSum(data)
        .then((response) => {
          params.chk = response.data;

          self.$store.commit('clearBasket');

          if (process.env.VUE_APP_MODE === 'staging') {
            window.location.href = `https://ssl.dotpay.pl/test_payment/?${$.param(params)}`;
          } else {
            window.location.href = `https://ssl.dotpay.pl/t2/?${$.param(params)}`;
          }
        })
        .catch(() => {
          self.error = 'Wystąpił błąd podczas generowania linku płatności. Proszę spróbować ponowanie lub zgłosić problem.';
          self.isPaymentRedirectionPending = false;
        });
    },
    verifyCoupon() {
      const self = this;
      this.isLoadingCoupon = true;
      this.clearMessages();

      tutorApi.basket.getCoupon(this.coupon)

        .then((response) => {
          const coupon = response.data;

          if (coupon) {
            self.applyCoupon(response.data);
            self.success = 'Kupon zostal dodany.';
          } else {
            self.removeCoupon();
            self.error = 'Taki kupon nie istnieje.';
          }
        })
        .catch((error) => {
          this.error = 'Wystapil blad podczas apliakcji kuponu.';
        })
        .finally(() => self.isLoadingCoupon = false);
    },
    copyToClipboard(e) {
      const { target } = e;
      const input = $(target).parents('.ui.action').find('input');
      input.select();
      input[0].setSelectionRange(0, 99999);
      navigator.clipboard.writeText(input.val());
    },
    applyCoupon(coupon) {
      this.couponPrice = coupon.price;
    },
    removeCoupon() {
      this.couponPrice = null;
    },
    removeItem(index) {
      this.$store.commit('removeItem', index);
    },
    clearMessages() {
      this.error = '';
      this.success = '';
    },
  }
};
</script>

<style lang="scss">
.BasketCheckoutModal {

  > .content {
    position: relative;
    min-height: 260px;
  }

  .promoPrice {
    color: red;
  }

  .emptyBasket {
    text-align: center;
    padding: 40px 0;
  }

  .checkoutForm {
    margin: 40px 0;
  }

  .productList {
    .productDescription {
      position: relative;

      .removeButton {
        display: none;
        position: absolute;
        right: 6px;
        top: 10px;
      }

      &:hover {
        .removeButton {
          display: block;
        }
      }
    }

    .productPrice {
      text-align: center;
    }

    .priceHeader {
      text-align: center;
    }
  }

  .ui.header:first-child {
    margin-top: revert;
  }
}
</style>
