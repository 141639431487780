<template>
  <div class="ui message" v-bind:class="classObject">
    <i class="close icon" @click="close()"></i>
    <div class="header">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'message',
  props: {
    type: {
      type: String,
      default: '',
    },
  },
  computed: {
    classObject() {
      return {
        negative: this.type === 'negative' || this.type === 'error',
        positive: this.type === 'positive' || this.type === 'success',
      };
    }
  },
  methods: {
    close() {
      this.$emit('close');
    }
  }
};
</script>

<style lang="scss">

</style>
