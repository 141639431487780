<template>
  <div class="tutor-routing" :class="{'logged-in': isStudentAuthenticated}">
    <Loader v-if="isLoading" />
    <div v-else>
      <div v-if="isError" class="tutor-routing__error">
        <h1>Wystąpił błąd</h1>
        <h2 @click="reload()" class="tutor-routing__error-reload">Kliknij, aby odświeżyć</h2>
      </div>
      <div v-else-if="!isStudentAuthenticated && (isCalendar || (isHomepage && !homepageEnabled))">
        <div class="tutor-header-wrapper">
          <TutorHeader
            :route="route"
            :is-homepage="isHomepage"
            :is-student-authenticated="isStudentAuthenticated"
            v-on:sign-out="onSignOut"
            v-on:open-login="onOpenLogin"
          />
        </div>
        <BasketThankYou />
        <div class="ui container tutor-routing__public-calendar">
          <PublicCalendar :booking-enabled="bookingEnabledPublic" />
        </div>
      </div>
      <div v-else>
        <div class="tutor-header-wrapper">
          <TutorHeader
            :route="route"
            :is-homepage="isHomepage"
            :is-student-authenticated="isStudentAuthenticated"
            v-on:sign-out="onSignOut"
            v-on:open-login="onOpenLogin"
          />
        </div>
        <BasketThankYou />
        <StudentDashboard v-if="isStudentAuthenticated" />
        <StudentRegistration v-else-if="isRegistration" :teacher-id="tutorPageTeacherId" />
<!--        <Tutor v-else-if="isHomepage" :teacher-id="tutorPageTeacherId" :page-content="pageContent" />-->
        <TutorTemplate v-else-if="isHomepage" :teacher-id="tutorPageTeacherId" />
      </div>

      <BasketCheckoutModal
        v-if="isBasketCheckoutModalActive"
        @close-modal="isBasketCheckoutModalActive = false"
      />
      <BasketAddItemModal
        v-if="isBasketAddItemModalActive"
        @close-modal="isBasketAddItemModalActive = false"
      />
      <BasketChangeItemDateModal
        v-if="isChangeItemDateModalActive"
        @close-modal="isChangeItemDateModalActive = false"
      />
      <Toast />
    </div>
  </div>
</template>

<script>
import api from '@/api/api';
import basketHelpers from '@/common/mixins/basketHelpers';
import Loader from '@/common/components/Loader.vue';
import PublicCalendar from '@/index/components/PublicCalendar.vue';
import Tutor from '@/index/components/Tutor.vue';
import TutorHeader from '@/index/components/TutorHeader.vue';
import StudentRegistration from '@/index/components/StudentRegistration.vue';
import StudentDashboard from '@/index/components/StudentDashboard.vue';
import BasketCheckoutModal from '@/index/components/Basket/BasketCheckoutModal.vue';
import BasketAddItemModal from '@/index/components/Basket/BasketAddItemModal.vue';
import BasketThankYou from '@/index/components/Basket/BasketThankYou.vue';
import BasketChangeItemDateModal from '@/index/components/Basket/BasketChangeItemDateModal';
import Toast from '@/common/components/Toast.vue';
import TutorTemplate from '@/index/components/TutorTemplate.vue';

export default {
  name: 'TutorPage',
  components: {
    Loader,
    PublicCalendar,
    Tutor,
    TutorTemplate,
    TutorHeader,
    StudentRegistration,
    StudentDashboard,
    BasketCheckoutModal,
    BasketAddItemModal,
    BasketThankYou,
    BasketChangeItemDateModal,
    Toast,
  },
  mixins: [basketHelpers],
  data() {
    return {
      isError: false,
      pageType: null,
      route: '',
      bookingEnabled: null,
      homepageEnabled: null,
      pageContent: '',
      isStudentVerified: false,
      isLoginModalActive: false,
      isTutorIdPending: true,
    };
  },
  computed: {
    isHomepage() {
      return this.pageType === 'homepage';
    },
    isCalendar() {
      return this.pageType === 'calendar';
    },
    isRegistration() {
      return this.pageType === 'register';
    },
    isStudentAuthenticated() {
      return !!this.$store.state.authentication.authenticatedStudentId;
    },
    tutorPageTeacherId() {
      return this.$store.state.tutorPage.teacherId;
    },
    isLoading() {
      return this.$store.state.authentication.isAuthenticationConfirmationPending && !this.isError;
    },
    bookingEnabledLoggedIn() {
      return this.$store.state.tutorPage.publicSettings.bookingEnabledLoggedIn;
    },
    bookingEnabledPublic() {
      return this.$store.state.tutorPage.publicSettings.bookingEnabledPublic;
    },
  },
  created() {
    const self = this;
    this.route = this.$route.params.name;
    this.pageType = this.$route.meta.pageType;

    console.log(this.$route);

    document.title = 'Ładowanie...';

    api.tutors.getTutorByRouteName(this.route)
      .then((querySnapshot) => {
        if (querySnapshot.size > 0) {
          querySnapshot.forEach((doc) => {
            const data = doc.data();

            self.$store.commit('SET_TUTOR_PAGE_TEACHER_ID', doc.id);
            self.$store.commit('SET_TUTOR_PAGE_PUBLIC_SETTINGS', data);
            self.$store.dispatch('loadTeacherPriceLists');
            self.bookingEnabled = data.bookingEnabled;
            self.homepageEnabled = data.homepageEnabled;
            self.pageContent = data.pageContent;

            document.title = data.businessName;
          });
        } else {
          self.isError = true;
        }
      })
      .catch((error) => {
        console.log(error);
        self.isError = true;
      })
      .finally(() => {
        this.isTutorIdPending = false;
      });
  },
  mounted() {
    const self = this;

    $(this.$el).on('click', '.top-navigation a.scroll', function (event) {
      event.preventDefault();

      self.scrollSmoothlyTo(this.hash);
    });
  },
  methods: {
    reload() {
      window.location.reload();
    },
    onSignOut() {
      api.signOut();
    },
    onOpenLogin() {
      this.$store.commit('SET_IS_LOGIN_MODAL_OPEN', true);
    },
    scrollSmoothlyTo(hashName) {
      const target = $(hashName);

      $('html, body').animate({
        scrollTop: target.offset().top - 65
      }, 1000);
    }
  },
};
</script>

<style lang="scss">
.tutor-routing {
  .tutor-header-wrapper {
    height: 64px;
  }

  &__public-calendar {
    margin-top: 40px;
  }

  &__error {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    &-reload {
      cursor: pointer;
    }
  }
}

.tutor-routing.logged-in {
  .tutor-header-wrapper {
    height: 90px;
  }
}

@media only screen and (max-width: 900px) {
  .tutor-routing.logged-in {
    .tutor-header-wrapper {
      height: 70px;
    }
  }
}
</style>
