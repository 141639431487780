<script>
import Api from '@/api/api';
import { marked } from 'marked';

export default {
  props: ['teacherId'],
  data() {
    return {
      businessCardSettings: {},
      isLoading: true,
      isDraft: false
    };
  },
  created() {
    this.isDraft = this.$route.meta.isDraft;

    Api.tutors.getBusinessCardSettings(this.teacherId, this.isDraft)
      .then((doc) => {
        this.businessCardSettings = doc.data();
      })
      .catch(() => {
        this.isError = true;
      })
      .finally(() => {
        setTimeout(() => this.isLoading = false, 500);
      });
  },
  methods: {
    marked: (input) => marked(input || '')
  }
};
</script>

<template>
  <div class="ui center aligned message" v-if="isDraft">
    <div class="content">
      <div class="header">
        To jest podgląd szkicu. Widoczny tylko dla Ciebie.
      </div>
      <p>Aby ją opublikować, przejdź proszę do 'Wizytówki' w panelu administracyjnym</p>
    </div>
  </div>
  <div class="tutor" :class="{'hidden': isLoading}">
    <div class="top-wrapper">
      <div class="top-bar">
        <div class="ui container">
          <ul class="top-navigation">
            <li><a href="#o-mnie" class="scroll">O mnie</a></li>
            <li><a href="#cennik" class="scroll">Cennik</a></li>
            <li><a href="#kontakt" class="scroll">Kontakt</a></li>
          </ul>
        </div>
      </div>
      <div class="ui container">
        <div class="intro">
          <div class="intro-message">
            <div class="message" v-html="marked(businessCardSettings?.introduction?.content)">
            </div>
          </div>
          <div class="intro-image">
            <img :src="businessCardSettings?.introduction?.imageUrl" />
          </div>
        </div>
      </div>
    </div>

    <div class="about-wrapper" id="o-mnie">
      <div class="ui container">
        <div class="about-entry" v-for="(entry, index) in businessCardSettings?.about">
            <template v-if="index % 2 === 0">
              <div class="about-entry-description" >
                <div class="description" v-html="marked(entry.content)">
                </div>
              </div>
              <div class="about-entry-image" v-if="entry.imageUrl">
                <img :src="entry.imageUrl" class="tutor-photo" />
              </div>
            </template>
            <template v-else>
              <div class="about-entry-image" v-if="entry.imageUrl">
                <img :src="entry.imageUrl" class="tutor-photo" />
              </div>
              <div class="about-entry-description">
                <div class="description" v-html="marked(entry.content)">
                </div>
              </div>
            </template>
        </div>
      </div>
    </div>

    <div class="price-wrapper" id="cennik">
      <div class="ui container" v-html="marked(businessCardSettings?.pricing?.content)">
      </div>
    </div>

    <div class="contact-wrapper" id="kontakt">
      <div class="ui container">
        <div v-html="marked(businessCardSettings?.contact?.content)"></div>
      </div>
    </div>

    <div class="footer-wrapper">
      &copy; 2023 Tutorro
    </div>
  </div>
</template>

<style lang="scss">
.tutor {
  .hidden {
    visibility: hidden;
  }

  .top-wrapper {
    padding: 1px 0 55px;
    background-color: v-bind('businessCardSettings?.introduction?.color');

    .top-bar {
      display: flex;
      justify-content: space-between;
      margin-top: 40px;

      .top-navigation {
        display: flex;
        margin: 0;
        justify-content: flex-end;
        font-size: 1.2em;

        li {
          list-style: none;

          a {
            color: v-bind('businessCardSettings?.introduction?.textColor');
            margin-right: 30px;
            font-size: 1.3em;

            &:hover {
              color: v-bind('businessCardSettings?.introduction?.textColor');
              text-decoration: underline;
            }
          }
        }
      }

      .logo-name {
        cursor: pointer;
      }
    }
  }

  .intro {
    display: flex;
    margin-top: 35px;

    .intro-message, .intro-image {
      width: 50%;
    }

    .intro-message {
      display: flex;
      justify-content: center;
      align-items: center;
      padding-right: 20px;
      color: v-bind('businessCardSettings?.introduction?.textColor');

      .message {
        a {
          color: v-bind('businessCardSettings?.introduction?.textColor');

          &:hover {
            color: v-bind('businessCardSettings?.introduction?.textColor');
            text-decoration: underline;
          }
        }

        .header {
          font-size: 2.5em;
        }

        p {
          font-size: 1.5em;
        }

        .button {
          border-radius: 30px;
          margin-top: 35px;
        }
      }
    }

    .intro-image {
      position: relative;

      img {
        max-width: 100%;
      }

      .high-dreamer {
        position: absolute;
        top: 0;
        left: 35px;
        animation: dreamer 2s infinite;
        animation-timing-function: ease-in-out;
      }
    }
  }

  .about-wrapper {
    padding: 40px 0;
  }

  .about-entry {
    display: flex;
    margin: 40px 0;

    &:first-of-type {
      margin-top: 0;
    }

    &:last-of-type {
      margin-bottom: 0;
    }

    .about-entry-description {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 60%;

      .description {
        width: 70%;
        font-size: 1.2em;
        line-height: 2em;

        > div {
          font-size: 1.2em;
          line-height: 2em;
        }

        li {
          text-align: left;
        }
      }
    }
    .about-entry-image {
      width: 40%;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        width: 100%;
      }

      //.tutor-photo {
      //  max-width: 400px;
      //  border-radius: 30px;
      //  box-shadow: 0 0 20px 4px #ececec;
      //}
    }
  }

  .form-wrapper {
    //background-color: #fff5e5;
    padding: 40px 0;

    .content {
      width: 400px;
    }
  }

  .price-wrapper {
    background-color: v-bind('businessCardSettings?.introduction?.color');
    color: v-bind('businessCardSettings?.introduction?.textColor');
    padding: 40px 0;
    font-size: 1.2em;
    line-height: 2em;

    .ui.container {
      width: 300px;
      margin: 0 auto;
    }
  }

  .contact-wrapper {
    text-align: center;
    margin: 50px 0;

    > div {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    p {
      font-size: 1.2em;
    }

    .contact-boxes {
      display: flex;
      justify-content: space-between;
      width: 500px;
      margin: 40px auto 0;
      flex-wrap: wrap;

      .icon {
        font-size: 2em;
      }

      .box {
        flex: 1 1 0px;
        min-width: 33%;
        margin: 25px 0;
      }
    }
  }

  .calendar-wrapper {
    text-align: center;
    margin: 80px 0;
  }

  .video {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .footer-wrapper {
    text-align: center;
    padding: 20px 0;
    background-color: #242424;
    color: #fff;
  }

  .ui.huge.blue.button {
    background-color: #34ace0;
  }

  .booking-modal {
    padding: 30px 0;
  }

  @keyframes dreamer {
    0% { top: 0; }
    50% { top: -10px }
    100% { top: 0; }
  }

}
@media only screen and (max-width: 1024px) {
  #root {
    .calendar-wrapper, .contact-wrapper {
      margin: 30px 0;
    }

    .top-wrapper {
      padding: 15px 0;
    }

    .top-bar {
      justify-content: center;
      margin-bottom: 30px;
    }

    .intro-image {
      width: 100%;
    }

    .logo-name {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      text-align: left;
      font-size: 16px;
    }

    .top-navigation {
      justify-content: center;
    }

    .login {
      padding: 10px;
      white-space: nowrap;
    }

    .intro {
      flex-direction: column-reverse;
      margin-top: 0;

      .intro-message {
        width: auto;
      }
    }

    .contact-boxes {
      width: auto;
      flex-direction: column;

      .box {
        margin-bottom: 40px;
      }
    }

    .form-wrapper {
      .content {
        max-width: 80%;
      }
    }

    .about-entry {
      flex-direction: column-reverse;

      &:nth-of-type(2) {
        flex-direction: column;
      }

      .about-entry-description {
        width: 100%;

        .description {
          width: 90%;
          text-align: justify;
        }
      }

      .about-entry-image {
        width: 100%;
        margin-bottom: 20px;
      }
    }
  }
}
</style>
