<template>
  <div class="my-classes">
    <Loader v-if="isLoading" />

    <div>
      <button v-if="isUnPaidClassesAvailable" class="ui right floated blue icon button" @click="addAllUnpaidClassesToBasket">
        <i class="shopping cart icon"></i> Opłać wszystkie
      </button>
    </div>

    <br />

    <template v-if="sortedClasses[0].length > 0">
      <h2>Trwające lekcje</h2>
      <div class="my-classes__list">
        <MyClassCard
          v-for="singleClass in sortedClasses[0]"
          :key="singleClass.id"
          :my-class="singleClass"
          :open-change-class-modal="openChangeClassModal"
          type="active" />
      </div>
    </template>

    <template v-if="sortedClasses[1].length > 0">
      <h2>Przyszłe lekcje</h2>
      <div class="my-classes__list">
        <MyClassCard
          v-for="singleClass in sortedClasses[1]"
          :key="singleClass.id"
          :my-class="singleClass"
          :open-change-class-modal="openChangeClassModal"
          type="future" />
      </div>
    </template>

    <template v-if="sortedClasses[2].length > 0">
      <h2>Poprzednie lekcje</h2>
      <div class="my-classes__list">
        <MyClassCard
          v-for="singleClass in sortedClasses[2]"
          :key="singleClass.id"
          :my-class="singleClass"
          :open-change-class-modal="openChangeClassModal"
          type="past" />
      </div>
    </template>

    <h2 v-if="!isLoading && !sortedClasses[0].length && !sortedClasses[1].length && !sortedClasses[2].length" class="my-classes__empty">
      Brak lekcji w historii
    </h2>

    <ChangeDateCalendar v-if="isChangeDateCalendarOpen" :on-close="closeChangeClassModal" />
  </div>
</template>

<script>
import api from '@/api/api';
import Loader from '@/common/components/Loader';
import ChangeDateCalendar from '@/index/components/StudentDashboard/ChangeDateCalendar';
import MyClassCard from '@/index/components/StudentDashboard/MyClassCard';

export default {
  name: 'MyClasses',
  components: {
    Loader,
    ChangeDateCalendar,
    MyClassCard,
  },
  data() {
    return {
      isChangeDateCalendarOpen: false,
    };
  },
  computed: {
    isLoading() {
      return this.$store.state.studentDashboard.isLoadingMyClasses;
    },
    myClasses() {
      return this.$store.state.studentDashboard.myClasses || [];
    },
    isUnPaidClassesAvailable() {
      return this.myClasses.find((myClass) => !myClass.paid && !myClass.transactionId && myClass.priceListId);
    },
    sortedClasses() {
      let myClassesFuture = [];
      let myClassesPast = [];
      let myClassesOnGoing = [];

      this.myClasses && this.myClasses.map((myClass) => {
        if (myClass.start > this.moment().valueOf()) {
          myClassesFuture.push(myClass);
        } else if (myClass.start + myClass.length * 60 * 1000 < this.moment().valueOf()) {
          myClassesPast.push(myClass);
        } else {
          myClassesOnGoing.push(myClass);
        }
      });

      myClassesFuture = myClassesFuture.sort((a, b) => a.start - b.start);
      myClassesPast = myClassesPast.sort((a, b) => b.start - a.start);
      myClassesOnGoing = myClassesOnGoing.sort((a, b) => b.start - a.start);

      return [myClassesOnGoing, myClassesFuture, myClassesPast];
    },
  },
  created() {
    this.$store.dispatch('loadMyClasses');
  },
  methods: {
    closeChangeClassModal() {
      this.isChangeDateCalendarOpen = false;
    },
    openChangeClassModal() {
      this.isChangeDateCalendarOpen = true;
    },
    addAllUnpaidClassesToBasket() {
      const unPaidClasses = this.myClasses.filter((myClass) => !myClass.paid && !myClass.transactionId && myClass.priceListId);
      let anythingAdded = false;

      for (const singleClass of unPaidClasses) {
        const alreadyExists = this.$store.state.basket.items.find((i) => i.timestamp === singleClass.start);
        const priceList = this.$store.state.tutorPage.studentPriceLists.find((p) => p.id === singleClass.priceListId);

        if (!alreadyExists || !priceList) {
          const basketItem = {
            timestamp: singleClass.start,
            length: singleClass.length,
            displayLength: singleClass.length,
            price: priceList
          };

          this.$store.commit('addItem', basketItem);
          anythingAdded = true;
        }
      }

      this.$store.dispatch('addNotification', { type: 'success', msg: 'Nieopłacone lekcje zostały dodane do koszyka.' });

      if (anythingAdded) {
        this.$store.commit('setBasketCheckoutModalState', true);
      }
    },
  },
};
</script>

<style lang="scss">
.my-classes {
  position: relative;
  min-height: 200px;

  &__list {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
  }

  &__empty {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 200px;
  }
}
</style>
