<template>
  <div class="BasketIcon" @click="openCheckout">
    <i class="shopping cart icon"></i>
    <div v-if="counter > 0" class="counter">{{counter}}</div>
  </div>
</template>

<script>
export default {
  name: 'BasketIcon',
  computed: {
    counter() {
      return this.$store.state.basket.items.length;
    }
  },
  methods: {
    openCheckout() {
      this.$store.commit('setBasketCheckoutModalState', true);
    }
  }
};
</script>

<style lang="scss">
  .BasketIcon {
    position: absolute;
    right: 0;
    top: 10px;
    cursor: pointer;

    .counter {
      position: absolute;
      top: -15px;
      right: -4px;
      background-color: #FCAC38;
      width: 25px;
      height: 25px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .icon {
      font-size: 2.5em;
    }
  }
</style>
