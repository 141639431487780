<template>
  <div class="change-calendar-date">
    <div id="change-calendar-date" class="ui modal">
      <i class="close icon"></i>
      <div class="header">
        Wybierz nową godzinę lekcji
      </div>
      <div class="content">
        <PublicCalendar :booking-enabled="true" mode="change" />
      </div>
      <div class="actions">
        <div class="ui black deny button">
          Zamknij
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PublicCalendar from '@/index/components/PublicCalendar.vue';

export default {
  name: 'ChangeDateCalendar',
  components: {
    PublicCalendar,
  },
  props: {
    onClose: Function,
  },
  mounted() {
    const self = this;

    $(this.$el).find('.ui.modal').modal({
      onHidden() {
        if (self.onClose) self.onClose();
        return false;
      }
    }).modal('show');
  },
  unmounted() {
    $('#change-calendar-date').remove();
  }
};
</script>

<style lang="scss">
.change-calendar-date {

}
</style>
