<template>
  <div class="student-dashboard ui container">
    <div class="ui top attached tabular menu">
      <div class="item" @click="setActiveTab('classes')" :class="{ 'active': activeTab === 'classes' }">Moje lekcje</div>
      <div class="item" @click="setActiveTab('calendar')" :class="{ 'active': activeTab === 'calendar' }">Kalendarz nauczyciela</div>
    </div>
    <div v-if="activeTab === 'classes'" class="ui bottom attached tab segment active">
      <MyClasses />
    </div>
    <div v-else-if="activeTab === 'calendar'" class="ui bottom attached tab segment active student-dashboard__public-calendar">
      <PublicCalendar
        :teacher-id="tutorPageTeacherId"
        :booking-enabled="bookingEnabledLoggedIn"
        :show-header="false"
      />
    </div>
  </div>
</template>

<script>
import PublicCalendar from '@/index/components/PublicCalendar.vue';
import MyClasses from '@/index/components/StudentDashboard/MyClasses.vue';

export default {
  name: 'StudentDashboard',
  components: {
    PublicCalendar,
    MyClasses,
  },
  data() {
    return {
      activeTab: 'classes'
    };
  },
  created() {
    this.$root.$on('modal-checkout-transfer-confirmed-closed', () => this.activeTab = 'classes');
    this.$store.dispatch('loadStudentDashboardPriceLists', this.$store.state.authentication.authenticatedStudentId);
  },
  computed: {
    bookingEnabledLoggedIn() {
      return this.$store.state.tutorPage.publicSettings.bookingEnabledLoggedIn;
    },
    tutorPageTeacherId() {
      return this.$store.state.tutorPage.teacherId;
    },
  },
  methods: {
    setActiveTab(tabName) {
      this.activeTab = tabName;
    },
  },
};
</script>

<style lang="scss">
.student-dashboard {
  margin: 20px 0;

  .public-calendar {
    margin: 10px;
  }

  .menu .item { cursor: pointer; }
}
</style>
