<script>
import Vue, { h, resolveComponent } from 'vue';
import BookingForm from './BookingForm.vue';
import PublicCalendar from './PublicCalendar.vue';

export default {
  props: {
    pageContent: {
      type: String,
      default: ''
    }
  },
  render() {
    Vue.component('instruction', {
      template: this.pageContent,
      components: {
        BookingForm,
        PublicCalendar,
      },
      data() {
        return {
          bookingTimestamp: 0,
          alternativeView: false,
        };
      },
      mounted() {
        this.alternativeView = !!this.$route.meta.alternativeView;
      },
      computed: {
        teacherId() {
          return process.env.VUE_APP_MODE === 'staging' ? 'xzvWWPCkUzfFfW9b5oZXEj9pBsP2' : 'jGVADSl9JRNeNzaHpmOPBCqko1K3';
        },
      },
      methods: {
        openBooking() {
          this.$emit('openBooking');
        },
        onBookingSlotClicked(timestamp) {
          this.bookingTimestamp = timestamp;
          $('.ui.modal').modal('show');
        },
      }
    });

    const component = resolveComponent('instruction');

    return h(
      'div',
      [
        h(component)
      ]
    );
  },
};
</script>

<style lang="scss" scoped>
  .top-wrapper {
    padding: 1px 0 55px;
    background-color: #fff5e5;

    .top-bar {
      display: flex;
      justify-content: space-between;
      margin-top: 40px;

      .top-navigation {
        display: flex;
        margin: 0;
        justify-content: flex-end;
        font-size: 1.2em;

        li {
          list-style: none;

          a {
            color: #000;
            margin-right: 30px;
            font-size: 1.3em;

            &:hover {
              color: #34ace0;
            }
          }
        }
      }

      .logo-name {
        cursor: pointer;
      }
    }
  }

  .intro {
    display: flex;
    margin-top: 35px;

    .intro-message, .intro-image {
      width: 50%;
    }

    .intro-message {
      display: flex;
      justify-content: center;
      align-items: center;

      .message {
        .header {
          font-size: 2.5em;
        }

        p {
          font-size: 1.5em;
        }

        .button {
          border-radius: 30px;
          margin-top: 35px;
        }
      }
    }

    .intro-image {
      position: relative;

      .high-dreamer {
        position: absolute;
        top: 0;
        left: 35px;
        animation: dreamer 2s infinite;
        animation-timing-function: ease-in-out;
      }
    }
  }

  .about-wrapper {
    padding: 40px 0;
  }

  .about-entry {
    display: flex;
    margin: 40px 0;

    &:first-of-type {
      margin-top: 0;
    }

    &:last-of-type {
      margin-bottom: 0;
    }

    .about-entry-description {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 60%;

      .description {
        max-width: 70%;

        > div {
          font-size: 1.2em;
          line-height: 2em;
        }
      }
    }
    .about-entry-image {
      width: 40%;
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        width: 100%;
      }

      .tutor-photo {
        max-width: 400px;
        border-radius: 30px;
        box-shadow: 0 0 20px 4px #ececec;
      }
    }
  }

  .form-wrapper {
    background-color: #fff5e5;
    padding: 40px 0;

    .content {
      width: 400px;
    }
  }

  .price-wrapper {
    background-color: #fff5e5;
    padding: 40px 0;

    .ui.container {
      width: 300px;
      margin: 0 auto;
    }
  }

  .contact-wrapper {
    text-align: center;
    margin: 50px 0;

    > div {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    p {
      font-size: 1.2em;
    }

    .contact-boxes {
      display: flex;
      justify-content: space-between;
      width: 500px;
      margin: 40px auto 0;
      flex-wrap: wrap;

      .icon {
        font-size: 2em;
      }

      .box {
        flex: 1 1 0px;
        min-width: 33%;
        margin: 25px 0;
      }
    }
  }

  .calendar-wrapper {
    text-align: center;
    margin: 80px 0;
  }

  .video {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .footer-wrapper {
    text-align: center;
    padding: 20px 0;
    background-color: #242424;
    color: #fff;

  }

  .ui.huge.blue.button {
    background-color: #34ace0;
  }

  .booking-modal {
    padding: 30px 0;
  }

  @keyframes dreamer {
    0% { top: 0; }
    50% { top: -10px }
    100% { top: 0; }
  }

  @media only screen and (max-width: 900px) {
    #root {
      .calendar-wrapper, .contact-wrapper {
        margin: 30px 0;
      }

      .top-wrapper {
        padding: 15px 0;
      }

      .intro-image {
        display: none;
      }

      .logo-name {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        text-align: left;
        font-size: 16px;
      }

      .top-navigation {
        padding-left: 20px;
      }

      .login {
        padding: 10px;
        white-space: nowrap;
      }

      .intro {
        flex-direction: column;
        margin-top: 0;

        .intro-message {
          width: auto;
        }
      }

      .contact-boxes {
        width: auto;
        flex-direction: column;

        .box {
          margin-bottom: 40px;
        }
      }

      .form-wrapper {
        .content {
          max-width: 80%;
        }
      }

      .about-entry {
        flex-direction: column-reverse;

        &:nth-of-type(2) {
          flex-direction: column;
        }

        .about-entry-description {
          width: 100%;

          .description {
            max-width: 100%;
            text-align: justify;
          }
        }

        .about-entry-image {
          width: 100%;
          margin-bottom: 20px;
        }
      }
    }
  }
</style>
