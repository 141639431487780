import 'fomantic-ui-css/semantic.css';
import 'fomantic-ui-css/semantic';

import { createApp } from 'vue';
import * as moment from 'moment';
import App from './App.vue';
import router from './router';
import store from '../store';
// import './ui';
import 'moment-timezone';
import 'moment/locale/pl';

import * as Sentry from '@sentry/browser';

moment.locale('pl');
// moment.tz.setDefault('Europe/Warsaw');

const app = createApp(App);

app.config.globalProperties.moment = moment;
app.config.globalProperties.momentGuess = moment.tz.guess;

app.use(store).use(router).mount('#app');

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: 'https://ae84683ceeb340bdbeb38a4a24d1e14b@sentry.io/1399554',
    integrations: [new Sentry.Integrations.Vue({
      Vue,
      attachProps: true
    })]
  });
}
