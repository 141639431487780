<template>
  <div class="booking-form">
    <div class="intro" v-if="!noText">
      <div class="ui huge header">
        Rezerwacja lekcji
      </div>
      <p v-if="bookingTimestamp > 0" style="text-align: center;">
        Darmowa 30-minutowa lekcja hiszpańskiego z Justyną <br />
        <strong>{{ printBookingTimeInfo }}</strong><br />
        (wg polskiej strefy czasowej)
      </p>
      <p v-else>
        Oferuję Tobie 30-minut darmowego spotkania, gdzie omówimy Twoje potrzeby i to co mogę zaoferować.
      </p>
    </div>
    <div class="content">
      <div class="description">
        <form class="ui form trial-form">
          <div v-if="simplified" class="simplified">
            <h3>Lub skontaktuję się z Tobą, proszę podaj swój numer telefonu:</h3>
            <div class="field">
              <input type="text" name="phone" v-model="phone" placeholder="Numer telefonu">
            </div>
          </div>
          <div v-else>
            <div class="field">
              <input type="text" name="studentName" v-model="studentName" placeholder="Imię i nazwisko">
            </div>
            <div class="field">
              <input type="email" name="email" v-model="email" placeholder="Adres e-mail">
            </div>
            <div class="field">
              <input type="text" name="phone" v-model="phone" placeholder="Telefon kontaktowy">
            </div>
            <div class="field">
              <input type="text" name="skype" v-model="skype" placeholder="Skype">
            </div>
            <div class="field">
              <label>Poziom języka *</label>
              <select name="level" v-model="level" class="ui dropdown language-level">
                <option value="">Wybierz poziom języka</option>
                <option value="a0">Brak znajomości języka</option>
                <option value="a1">Początkujący A1</option>
                <option value="a2">Początkujący A2</option>
                <option value="b1">Średniozaawansowany B1</option>
                <option value="b2">Średniozaawansowany B2</option>
                <option value="c1">Zaawansowany C1</option>
                <option value="c2">Zaawansowany C2</option>
              </select>
            </div>
            <div class="field">
              <label>Jaki jest cel nauki? Miejsce na dodatkowy komentarz.</label>
              <textarea rows="2" name="comment" v-model="comment"></textarea>
            </div>
            <div class="inline fields">
              <div class="field">
                <div class="ui radio checkbox">
                  <input type="radio" name="price" v-model="price" value="0" checked="checked" class="hidden">
                  <label>Próbna lekcja 30 minut - 0 zł</label>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
    <div class="actions">
      <div v-bind:class="{ hidden: success }">
        <div class="ui huge blue button book-trial-button" @click="bookNow()">
          Wyślij
        </div>
      </div>

      <div class="ui success message" v-bind:class="{ hidden: !success }">
        <div class="center aligned header">
          Twoje zgłoszenie zostało przesłane do nauczyciela.<br />
          Wkrótce się z Tobą skontakuje, aby ustalić szczegóły pierwszej lekcji.
        </div>
      </div>
      <div class="ui negative message" v-bind:class="{ hidden: !failure }">
        <div class="header">
          Wystąpił błąd. Proszę spróbować jeszcze raz lub <br />skontaktuj się proszę ze mną bezpośrednio: +48 798 672 005.
        </div>
      </div>
    </div>

    <div class="ui inverted dimmer" v-bind:class="{ active: isWaiting }">
      <div class="ui small text loader">Wysyłamy formularz do nauczyciela...</div>
    </div>
  </div>
</template>

<script>
import tutorApi from '@/api/api';

export default {
  name: 'BookingForm',
  props: {
    bookingTimestamp: {
      type: Number,
    },
    bookingLength: {
      type: Number,
    },
    simplified: {
      type: Boolean,
    },
    noText: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      studentName: null,
      email: null,
      phone: null,
      skype: null,
      level: null,
      comment: null,
      classForm: null,
      success: false,
      failure: false,
      isWaiting: false,
      price: 0
    };
  },
  computed: {
    bookingStartTime() {
      return this.moment(this.bookingTimestamp).format('HH:mm');
    },
    bookingEndTime() {
      return this.moment(this.bookingTimestamp).add(this.bookingLength, 'm').format('HH:mm');
    },
    bookingStartDate() {
      return this.moment(this.bookingTimestamp).add(this.bookingLength, 'm').format('dddd, DD.MM.YYYY');
    },
    printBookingTimeInfo() {
      return `${this.bookingStartDate}, od ${this.bookingStartTime} do ${this.bookingEndTime}`;
    }
  },
  mounted() {
    $(this.$el).find('.dropdown.laguage-level').dropdown();
    $(this.$el).find('.ui.checkbox.radio').checkbox();

    this.classForm = $(this.$el).find('.ui.form');
    this.classForm.form({
      fields: {
        studentName: 'empty',
        email: 'email',
        level: 'empty'
      }
    });
  },
  methods: {
    open() {
      $(this.$el).modal('show');
    },
    bookNow() {
      if (this.classForm.form('is valid') || (this.simplified && this.email)) {
        this.isWaiting = true;

        tutorApi.makeClassRequest({
          studentName: this.studentName,
          email: this.email,
          phone: this.phone,
          level: this.level,
          comment: this.comment,
          price: this.price,
          time: this.printBookingTimeInfo,
        }).then(() => {
          this.setSuccess();
          window.dataLayer.push({ event: 'classBooked' });
        }).catch(() => {
          this.setFailure();
        });
      } else {
        this.classForm.form('validate form');
      }
    },
    setSuccess() {
      this.success = true;
      this.failure = false;
      this.isWaiting = false;
    },
    setFailure() {
      this.success = false;
      this.failure = true;
      this.isWaiting = false;
    },
    clear() {
      this.success = false;
      this.failure = false;
      this.is_waiting = false;
    }
  }
};
</script>

<style scoped lang="scss">
#root {
  .intro-message {
    .booking-form {
      display: block;

      .ui.success {
        .header {
          font-size: 1em;
        }
      }
    }
  }

  .booking-form {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .intro {
      text-align: center;
      flex-direction: column;
    }
  }

  .book-trial-button {
    border-radius: 30px;
    margin-top: 20px;

    &:hover {
      background-color: #1678c2;
    }
  }

  p {
    font-size: 1.1em;
  }

  .trial-form  {
    margin-top: 30px;

    input {
      border-radius: 30px;
      line-height: 2em;
      border-color: #000;
    }
  }

  .booking-modal {
    .simplified input {
      width: 300px;
    }
  }

  .actions {
    text-align: center;
  }
}
</style>
