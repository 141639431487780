<template>
  <div id="app">
    <router-view/>
    <Authentication entry="index" />
  </div>
</template>

<script>
import Authentication from '@/common/components/Authentication.vue';

export default {
  name: 'App',
  components: {
    Authentication,
  },
};
</script>
