<template>
  <div class="ui modal basket-modal">
    <i class="close icon"></i>
    <div class="header">
      Potwierdź zmianę godziny lekcji
    </div>
    <div class="content">
      <h3>dotychczasowy termin:</h3>
      <h3 class="ui header">
        {{printDay(changeDateItem.start)}} godz. {{printHour(changeDateItem.start)}}
      </h3>
      <h3>nowy termin:</h3>
      <h2 class="ui header">
        {{printDay(changeDateItem.newStart)}} godz. {{printHour(changeDateItem.newStart)}}
      </h2>

    </div>
    <div class="actions">
      <div class="ui black deny button">
        Zamknij
      </div>
      <div class="ui primary right labeled icon button" @click="requestChange">
        Potwierdzam zmianę godziny
        <i class="checkmark icon"></i>
      </div>
    </div>
    <Loader v-if="isLoading" />
  </div>
</template>

<script>
import calendarHelpers from '@/common/mixins/calendarHelpers';
import Api from '@/api/api';
import Loader from '@/common/components/Loader.vue';

export default {
  name: 'BasketChangeItemDateModal',
  components: {
    Loader,
  },
  mixins: [calendarHelpers],
  props: {
    close: {
      type: Function
    }
  },
  data() {
    return {
      priceIndex: null,
      isLoading: false,
    };
  },
  mounted() {
    const self = this;

    $(this.$el).modal({
      onHidden() {
        self.$emit('close-modal');
      }
    }).modal('show');
  },
  unmounted() {
    this.$store.commit('setCurrentAddToBasketItem', null);
  },
  methods: {
    requestChange() {
      this.isLoading = true;

      Api.calendar.changeEventDate({
        eventId: this.changeDateItem.id,
        newStart: this.changeDateItem.newStart,
        timezone: this.timezone,
      })
        .then((resp) => {
          if (resp.data === 'ok') {
            this.$store.commit('CHANGE_CLASS_DATE', {
              eventId: this.changeDateItem.id,
              newStart: this.changeDateItem.newStart,
            });
            this.$store.dispatch('addNotification', { type: 'success', msg: 'Termin lekcji został zmieniony.' });
          }
        })
        .catch((error) => {
          let info = '';

          if (error.message === 'AT_LEAST_24_HOUR_NOTICE') {
            info = 'Jest mniej niż 24 godzin do wybranego terminu.';
          }

          this.$store.dispatch('addNotification', { type: 'failure', msg: `Nie można zmienić godziny lekcji ${info}` });
        })
        .finally(() => {
          this.isLoading = false;
          $(this.$el).modal('hide');
        });
    }
  },
  computed: {
    changeDateItem() {
      return this.$store.state.basket.classToBeChanged;
    }
  }
};
</script>

<style lang="scss">
  .basket-modal {
    .ui.checkbox {
      cursor: pointer;

      label {
        cursor: pointer;
      }
    }

    .content {
      text-align: center;
    }

    .ui.card {
      margin: 0 auto;
    }

    .field {
      line-height: 2em;

      label {
        font-size: 1.2em;
      }
    }
  }
</style>
