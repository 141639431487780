<template>
  <div class="student-registration ui container">
    <Loader v-if="isLoading" />
    <div v-if="!isError">
      <h1>Utwórz konto wpisując swoje hasło.</h1>
      <h3>Resztę danych uzupełnił Twój nauczyciel.</h3>
      <form class="ui form" v-on:submit.prevent>
        <div class="field">
          <label>Imię</label>
          <input type="text" v-model="firstName" placeholder="Imię" disabled />
        </div>
        <div class="field">
          <label>Nazwisko</label>
          <input type="text" v-model="lastName" placeholder="Nazwisko" disabled />
        </div>
        <div class="field">
          <label>E-mail</label>
          <input type="text" v-model="email" placeholder="E-mail" disabled />
        </div>
        <div class="field required">
          <label>Hasło</label>
          <input type="password" v-model="password" name="password">
        </div>
        <div class="field required">
          <label>Powtórz hasło</label>
          <input type="password" v-model="checkPassword" name="check-password">
        </div>
        <!--<div class="field">-->
        <!--<div class="ui checkbox">-->
        <!--<input type="checkbox" tabindex="0" class="hidden">-->
        <!--<label>I agree to the Terms and Conditions</label>-->
        <!--</div>-->
        <!--</div>-->
        <button class="ui primary button" type="submit" :class="{ loading: isFormPending }">Zarejestruj się</button>
        <div class="ui error message"></div>
      </form>
    </div>
    <div v-else>
      <div class="ui negative message">
        <i class="close icon"></i>
        <div class="header">
          Wystąpił błąd - nieprawidłowy kod rejestracji
        </div>
        <p>Skontaktuj się ze swoim nauczycielem.</p>
      </div>
    </div>
  </div>
</template>

<script>
import Api from '@/api/api.js';
import Loader from '@/common/components/Loader.vue';

export default {
  name: 'StudentRegistration',
  components: {
    Loader,
  },
  props: ['teacherId'],
  data() {
    return {
      isLoading: true,
      isFormPending: false,
      isError: false,
      firstName: null,
      lastName: null,
      email: null,
      password: null,
      checkPassword: null,
    };
  },
  created() {
    Api.students.getInvitationDetails({
      teacherId: this.teacherId,
      registrationKey: this.$route.query.key
    })
      .then((response) => {
        this.firstName = response.data.firstName;
        this.lastName = response.data.lastName;
        this.email = response.data.email;
      })
      .catch(() => {
        this.isError = true;
      })
      .finally(() => {
        this.isLoading = false;
      });
  },
  unmounted() {
    const link = window.location.pathname.replace('/register', '');
    this.$router.replace(link);
  },
  mounted() {
    $(this.$el).find('.ui.form').form({
      fields: {
        password: {
          identifier: 'password',
          rules: [
            {
              type: 'empty',
              prompt: 'Proszę wpisz swoje hasło'
            },
            {
              type: 'minLength[5]',
              prompt: 'Hasło musi mieć przynajmniej {ruleValue} znaków'
            }
          ]
        },
        checkPassword: {
          identifier: 'check-password',
          depends: 'password',
          rules: [
            {
              type: 'match[password]',
              prompt: 'Powtórzone hasło musi być takie samo'
            },
          ]
        }
      },
      onSuccess: this.onFormSuccess
    });
  },
  methods: {
    onFormSuccess(e) {
      e.preventDefault();

      this.isFormPending = true;

      Api.students.createStudentAccount({
        password: this.password,
        teacherId: this.teacherId,
        registrationKey: this.$route.query.key
      })
        .then(() => {
          Api.signInWithEmailAndPassword(this.email, this.password);
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.isFormPending = false;
        });
    },
  },
};
</script>

<style lang="scss">
.student-registration {
  position: relative;
  padding: 20px 0;

  .form {
    margin: 20px 0px;
  }
}
</style>
